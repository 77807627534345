'use client';

// components
import ErrorView from '@/app/_components/ErrorView';

interface Props {
  error: Error & { digest?: string };
  reset: () => void;
}

const Error = ({ error, reset }: Props) => {
  return <ErrorView reset={reset} />;
};

export default Error;
