import Link from 'next/link';
import { Button, Flex, HStack, Stack, Text } from '@chakra-ui/react';

interface Props {
  reset?: () => void;
  title?: string;
  subtitle?: string;
}

const ErrorView = ({
  reset,
  title = 'Something went wrong',
  subtitle = 'Apologies for the inconvenience. The Limo team has been notified.',
}: Props) => {
  return (
    <Flex
      minW="100%"
      maxW="200%"
      direction={'column'}
      alignItems={'center'}
      h={'100%'}
    >
      <Stack height="100%" display="flex" alignItems="center">
        <Text fontSize="2xl" fontWeight="extrabold" mb={4} pt={16}>
          {title}
        </Text>

        <Text fontSize="xl" fontWeight="bold" mb={2} align="center">
          {subtitle}
        </Text>

        <HStack spacing="16px" display="flex" alignItems="center" pt={6}>
          <Button variant="link">
            <Link href="/pending-audits">Go to Home</Link>
          </Button>
          {reset ? <Button onClick={reset}>Reload page</Button> : null}
        </HStack>
      </Stack>
    </Flex>
  );
};

export default ErrorView;
